import React, { useState } from 'react';
import { Input, Header, Form, Dropdown } from 'semantic-ui-react';
import { db } from '../../lib/firebase';
import { Box } from 'theme-ui';

export const SongInput = ({ windowWidth }) => {
  const [group, setGroup] = useState('');
  const [name, setName] = useState('');
  const [composer, setComposer] = useState('');
  const [id, setId] = useState('');
  const [category, setCategory] = useState('');
  const [recId, setRecId] = useState('');
  const [pianoId, setPianoId] = useState('');
  const [scoreId, setScoreId] = useState('');
  const [folderId, setFolderId] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const theId = id.substring(32,65);
    let theRecId, thePianoId, theScoreId, theFolderId;

    if(recId){
      theRecId = recId.substring(32,65);
    }
    if(pianoId){
      thePianoId = pianoId.substring(32,65);
    }
    if(scoreId){
      theScoreId = scoreId.substring(32,65);
    }
    if(folderId){
      theFolderId = folderId.substring(39,72);
    }

    const theSong = {
      name: name,
      composer: composer,
      id: theId,
      category: category,
      recId: theRecId,
      pianoId: thePianoId,
      scoreId: theScoreId,
      folderId: theFolderId,
    };

    const goodInfo = {};

    Object.entries(theSong).forEach(function (currElem) {
      const key = currElem[0];
      const value = currElem[1];

      if (value) {
        goodInfo[key] = value;
      }
    });

    if (group !== '') {
      db.collection(group).add(goodInfo);
      setGroup('');
      setName('');
      setComposer('');
      setId('');
      setCategory('');
      setRecId('');
      setPianoId('');
      setScoreId('');
      setFolderId('');
    }
  };

  const whatSize = () => {
    if (windowWidth < 550) return 'huge';
  };

  return (
    <Box sx={{ width: '500px' }}>
      <Form size={whatSize()} onSubmit={handleSubmit}>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: '20px' }}>
          <Header as='h1'>Add a song</Header>
        </Box>
        <Form.Field>
          <Header>Musical Group</Header>
          <Dropdown
            placeholder='Musical Group'
            value={group}
            onChange={(_, { value }) => setGroup(value)}
            fluid
            selection
            options={[
              {
                key: 'main-choir-songs',
                value: 'main-choir-songs',
                text: 'Main Choir',
              },
              {
                key: 'youth-choir-songs',
                value: 'youth-choir-songs',
                text: 'Youth Choir',
              },
              {
                key: 'strings-songs',
                value: 'strings-songs',
                text: 'Strings',
              },
              {
                key: 'ensemble-songs',
                value: 'ensemble-songs',
                text: 'Ensemble',
              },
            ]}
          />
        </Form.Field>
        <Form.Field>
          <Header>Song name</Header>
          <Input
            placeholder='Song name'
            value={name}
            name='name'
            onChange={(_, { value }) => setName(value)}
          />
        </Form.Field>
        <Form.Field>
          <Header>Composer</Header>
          <Input
            placeholder='Composer'
            value={composer}
            name='composer'
            onChange={(_, { value }) => setComposer(value)}
          />
        </Form.Field>
        <Form.Field>
          <Header>Drive ID</Header>
          <Input
            placeholder='Drive ID'
            value={id}
            name='id'
            onChange={(_, { value }) => setId(value)}
          />
        </Form.Field>
        <Form.Field>
          <Header>Category</Header>
          <Dropdown
            placeholder='Category'
            value={category}
            onChange={(_, { value }) => setCategory(value)}
            fluid
            selection
            options={[
              { key: 'baptism', value: 'baptism', text: 'Baptism' },
              { key: 'christmas', value: 'christmas', text: 'Christmas' },
              { key: 'communion', value: 'communion', text: 'Communion' },
              { key: 'easter', value: 'easter', text: 'Easter' },
              { key: 'harvest', value: 'harvest', text: 'Harvest' },
              { key: 'mens-choir', value: 'mens-choir', text: "Men's Choir" },
              { key: 'new-year', value: 'new-year', text: 'New Year' },
              { key: 'regular', value: 'regular', text: 'Regular' },
              {
                key: 'womens-choir',
                value: 'womens-choir',
                text: "Women's Choir",
              },
            ]}
          />
        </Form.Field>
        <Form.Field>
          <Header>Recording ID</Header>
          <Input
            placeholder='Recording ID'
            value={recId}
            name='recId'
            onChange={(_, { value }) => setRecId(value)}
          />
        </Form.Field>
        <Form.Field>
          <Header>Piano ID</Header>
          <Input
            placeholder='Piano ID'
            value={pianoId}
            name='pianoId'
            onChange={(_, { value }) => setPianoId(value)}
          />
        </Form.Field>
        <Form.Field>
          <Header>Score ID</Header>
          <Input
            placeholder='Score ID'
            value={scoreId}
            name='scoreId'
            onChange={(_, { value }) => setScoreId(value)}
          />
        </Form.Field>
        <Form.Field>
          <Header>Folder ID</Header>
          <Input
            placeholder='Folder ID'
            value={folderId}
            name='folderId'
            onChange={(_, { value }) => setFolderId(value)}
          />
        </Form.Field>
        {group && name && id ? (
          <Form.Button color='green'>Submit</Form.Button>
        ) : (
          <Box></Box>
        )}
      </Form>
    </Box>
  );
};
