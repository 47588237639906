import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const NavWrapper = styled.nav`
  text-align: center;
`;

export const StyledNavLink = styled(NavLink)`
  color: rgb(2, 73, 8);
  display: inline-block;
  padding-right: 10px;
  padding-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 15px;

  &.active {
    color: rgb(2, 73, 8);
    background: rgb(235, 235, 235);
    border-radius: 5px;
    padding-top: 4px;
    padding-bottom: 5px;
  }
`;
