import React, { useEffect, useState } from 'react';
import { Box } from 'theme-ui';
import { Image, Header } from 'semantic-ui-react';

import YouthChoirPageTitle from '../../icon-pics/YouthChoirPageTitle.jpg';
// import FirstSlideImage from '../../main-page-pics/BigGroupPic.JPG';

export const Title = () => {
  const [widthV, setWidth] = useState(window.innerWidth);

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  });

  return (
    <Box sx={{ margin: '10px' }}>
      {widthV < 420 ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '10px',
            paddingBottom: '25px',
          }}
        >
          <Image
            src={YouthChoirPageTitle}
            size='medium'
            style={{
              borderRadius: '5px',
              boxShadow: '0 0 5px #1d3c2c',
              // border: '0px solid #0000007d',
            }}
          ></Image>
        </Box>
      ) : (
        <Box
          sx={{ margin: '10px', textAlign: 'center', paddingBottom: '10px' }}
        >
          <Header style={{ fontSize: '50px', textAlign: 'center' }} href='/'>
            GSBC Music
          </Header>
        </Box>
      )}
    </Box>
  );
};
