import { Box } from 'theme-ui';
import { UpdateModal } from '../modal/update-modal';

export const DisplaySearchedSongs = ({
  windowWidth,
  songCollection,
  query,
  collectionName,
}) => {
  return (
    <Box sx={{ mt: '10px' }}>
      {songCollection
        .filter(({ name }) => name.toLowerCase().includes(query.toLowerCase()))
        .map((song) => (
          <Box
            key={song.id}
            sx={{
              justifyContent: 'space-between',
              alignItems: 'center',
              display: 'flex',
              padding: '5px 20px',
              fontSize: '16.5px',
              '@media (max-width: 768px)': {
                padding: '5px 30px',
              },
              '@media (max-width: 550px)': {
                padding: '5px ',
                fontSize: '17.5px',
              },
            }}
          >
            <Box
              sx={{
                padding: '4px',
                overflow: 'hidden',
                '@media (max-width: 630px)': {
                  maxWidth: '45vw',
                },
                '@media (max-width: 500px)': {
                  maxWidth: '50vw',
                  fontSize: '18.5px',
                },
                '@media (max-width: 360px)': {
                  maxWidth: '43vw',
                  fontSize: '18.5px',
                },
              }}
            >
              {song.name}
            </Box>
            <UpdateModal
              windowWidth={windowWidth}
              song={song}
              collectionName={collectionName}
            />
          </Box>
        ))}
    </Box>
  );
};
