import React, { useEffect, useState } from 'react';
import { Header, Dropdown } from 'semantic-ui-react';
import { Box, Flex } from 'theme-ui';

// import { DisplayCategory } from '../../utils/category-display';
import { useSongsContext } from '../../lib/songs-context';
import { SearchSong } from '../search';
import { ModalWindowNoDate } from '../song-listing';

export const Strings = ({windowWidth}) => {
  const { stringsSongs } = useSongsContext();

  const [query, setQuery] = useState('');
  const [stringsRegularSongs, setStringsRegularSongs] = useState([]);
  const [stringsChristmasSongs, setStringsChristmasSongs] = useState([]);
  const [stringsHarvestSongs, setStringsHarvestSongs] = useState([]);
  const [stringsEasterSongs, setStringsEasterSongs] = useState([]);
  const [stringsCommunionSongs, setStringsCommunionSongs] = useState([]);

  const [specificCategory, setSpecificCategory] = useState([]);

  const [categoryName, setCategoryName] = useState({
    theCategoryName: 'All Songs',
  });

  useEffect(() => {
    if (!stringsSongs.length) return;

    setSpecificCategory(stringsSongs);

    const regularSongs = stringsSongs.filter(
      ({ category }) => category === 'regular'
    );
    const christmasSongs = stringsSongs.filter(
      ({ category }) => category === 'christmas'
    );
    const harvestSongs = stringsSongs.filter(
      ({ category }) => category === 'harvest'
    );
    const easterSongs = stringsSongs.filter(
      ({ category }) => category === 'easter'
    );
    const communionSongs = stringsSongs.filter(
      ({ category }) => category === 'communion'
    );

    setStringsRegularSongs(regularSongs);
    setStringsChristmasSongs(christmasSongs);
    setStringsHarvestSongs(harvestSongs);
    setStringsEasterSongs(easterSongs);
    setStringsCommunionSongs(communionSongs);
  }, [stringsSongs]);

  const turnOnRegularSongs = () => {
    setSpecificCategory(stringsRegularSongs);
    setCategoryName({
      theCategoryName: 'Regular',
    });
  };
  const turnOnChristmasSongs = () => {
    setSpecificCategory(stringsChristmasSongs);
    setCategoryName({
      theCategoryName: 'Christmas',
    });
  };
  const turnOnHarvestSongs = () => {
    setSpecificCategory(stringsHarvestSongs);
    setCategoryName({
      theCategoryName: 'Harvest',
    });
  };
  const turnOnEasterSongs = () => {
    setSpecificCategory(stringsEasterSongs);
    setCategoryName({
      theCategoryName: 'Easter',
    });
  };
  const turnOnCommunionSongs = () => {
    setSpecificCategory(stringsCommunionSongs);
    setCategoryName({
      theCategoryName: 'Communion',
    });
  };
  const turnOnAllSongs = () => {
    setSpecificCategory(stringsSongs);
    setCategoryName({
      theCategoryName: 'All Songs',
    });
  };

  return (
    <Box
      sx={{
        paddingTop: '20px',
        maxWidth: '800px',
        margin: '0 auto',
      }}
    >
      <Header size='huge' textAlign='center'>
        Strings Orchestra
      </Header>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          paddingBottom: '15px',
        }}
      >
        <Dropdown
          button
          className='icon'
          floating
          labeled
          icon='angle double down'
          text={categoryName.theCategoryName}
          closeOnChange
        >
          <Dropdown.Menu>
            <Dropdown.Item onClick={turnOnAllSongs}>All Songs</Dropdown.Item>
            <Dropdown.Item onClick={turnOnChristmasSongs}>
              Christmas
            </Dropdown.Item>
            <Dropdown.Item onClick={turnOnCommunionSongs}>
              Communion
            </Dropdown.Item>
            <Dropdown.Item onClick={turnOnEasterSongs}>Easter</Dropdown.Item>
            <Dropdown.Item onClick={turnOnHarvestSongs}>Harvest</Dropdown.Item>
            <Dropdown.Item onClick={turnOnRegularSongs}>Regular</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Box>

      <Box sx={{ mt: '7px', mb: '10px' }}>
        <SearchSong windowWidth={windowWidth} query={query} setQuery={setQuery} />
      </Box>

      {specificCategory
        .filter(({ name }) => name.toLowerCase().includes(query.toLowerCase()))
        .map((song) => (
          <Box
            key={song.fireId}
            sx={{
              justifyContent: 'space-between',
              alignItems: 'center',
              display: 'flex',
              padding: '5px 20px',
              fontSize: '16.5px',
              '@media (max-width: 768px)': {
                padding: '5px 30px',
              },
              '@media (max-width: 550px)': {
                padding: '5px ',
                fontSize: '17.5px',
              },
              transition: 'background ease-out 0.3s',
              borderRadius: '.28571429rem',
              ':hover': { background: '#e5e4e2', color: '#5C095A' },
            }}
          >
            <Box
              sx={{
                padding: '4px',
                overflow: 'hidden',
                '@media (max-width: 630px)': {
                  maxWidth: '45vw',
                },
                '@media (max-width: 500px)': {
                  maxWidth: '50vw',
                },
                '@media (max-width: 360px)': {
                  maxWidth: '43vw',
                  fontSize: '15.5px',
                },
                ':hover': { color: '#152622' },
              }}
            >
              <a
                href={`https://drive.google.com/drive/folders/${song.id}?usp=sharing`}
                target='_blank'
                rel='noreferrer'
              >
                {song.name}
              </a>
            </Box>
            <Flex
              sx={{
                textAlign: 'right',
              }}
            >
              <ModalWindowNoDate eachSong={song} />
            </Flex>
          </Box>
        ))}
    </Box>
  );
};
