import React, { useEffect, useState } from 'react';
import { fire, provider } from '../../lib/firebase';
import { Box } from 'theme-ui';
import { Button, Icon, Header } from 'semantic-ui-react';
import { db } from '../../lib/firebase';

import { SongInput } from './song-input';
import { SongUpdate } from './update-song';

export const AdminPage = ({windowWidth}) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [allowedUsers, setAllowedUsers] = useState([]);
  const [userName, setUserName] = useState('');

  const fetchUsers = async () => {
    const snapshot = await db.collection('users').get();

    const res = snapshot.docs.map((user) => ({
      fireId: user.id,
      ...user.data(),
    }));

    setAllowedUsers(res);
  };

  useEffect(() => {
    (async () => {
      await fetchUsers();
    })();
  }, []);

  useEffect(() => {
    fire.auth().onAuthStateChanged((user) => {
      if (user == null) return;
      const foundUser = allowedUsers.find((o) => o.fireId === user.uid);
      if (foundUser) {
        setIsLoggedIn(true);
        setUserName(foundUser.name);
      }
    });
  }, [allowedUsers]);

  const signIn = () => {
    fire.auth().signInWithRedirect(provider);
  };

  const signOut = () => {
    fire.auth().signOut();
    setIsLoggedIn(false);
  };

  const whatSize = () => {
    if (windowWidth < 550) return 'huge';
    else return 'medium';
  };

  return (
    <Box>
      {!isLoggedIn ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: '30px' }}>
          <Button size={whatSize()} color='google plus' onClick={signIn}>
            <Icon name='google drive' />
            Sign In
          </Button>
        </Box>
      ) : (
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: '20px' }}>
            <Header size='large' textAlign='center'>Welcome, {userName}</Header>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: '20px' }}>
            <Button size={whatSize()} color='red' onClick={signOut}>
              Sign Out
            </Button>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: '20px' }}>
            <SongInput windowWidth={windowWidth}/>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: '20px' }}>
            <SongUpdate windowWidth={windowWidth}/>
          </Box>
        </Box>
      )}
    </Box>
  );
};
