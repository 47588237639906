import React, { useEffect, useState } from 'react';
import { useSongsContext } from '../../lib/songs-context';
// import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Header, Icon, Image, Label, Dropdown } from 'semantic-ui-react';
import { Box, Flex } from 'theme-ui';

import { ModalWindow } from '../song-listing';
import { SongTitle } from '../song-listing';
import { SearchSong } from '../search';
import PianoIcon from '../../icon-pics/PianoIcon.jpg';

export const YouthChoir = ({windowWidth}) => {
  const { youthChoirSongs } = useSongsContext();

  const [query, setQuery] = useState('');
  const [youthChoirRegular, setYouthChoirRegular] = useState([]);
  const [youthChoirCommunion, setYouthChoirCommunion] = useState([]);
  const [youthChoirEaster, setYouthChoirEaster] = useState([]);
  const [youthChoirNewYear, setYouthChoirNewYear] = useState([]);
  const [youthChoirChristmas, setYouthChoirChristmas] = useState([]);
  const [youthChoirHarvest, setYouthChoirHarvest] = useState([]);
  const [youthChoirSisters, setYouthChoirSisters] = useState([]);
  const [youthChoirBrothers, setYouthChoirBrothers] = useState([]);
  const [youthChoirBaptism, setYouthChoirBaptism] = useState([]);

  const [specificCategory, setSpecificCategory] = useState([]);

  const [categoryName, setCategoryName] = useState({
    theCategoryName: 'All Songs',
  });

  useEffect(() => {
    if (!youthChoirSongs.length) return;

    setSpecificCategory(youthChoirSongs);

    const regularYouthSongs = youthChoirSongs.filter(
      ({ category }) => category === 'regular'
    );
    const christmasYouthSongs = youthChoirSongs.filter(
      ({ category }) => category === 'christmas'
    );
    const harvestYouthSongs = youthChoirSongs.filter(
      ({ category }) => category === 'harvest'
    );
    const easterYouthSongs = youthChoirSongs.filter(
      ({ category }) => category === 'easter'
    );
    const communionYouthSongs = youthChoirSongs.filter(
      ({ category }) => category === 'communion'
    );
    const baptismYouthSongs = youthChoirSongs.filter(
      ({ category }) => category === 'baptism'
    );
    const menYouthSongs = youthChoirSongs.filter(
      ({ category }) => category === 'mens-choir'
    );
    const womenYouthSongs = youthChoirSongs.filter(
      ({ category }) => category === 'womens-choir'
    );
    const newYearYouthSongs = youthChoirSongs.filter(
      ({ category }) => category === 'new-year'
    );

    setYouthChoirRegular(regularYouthSongs);
    setYouthChoirChristmas(christmasYouthSongs);
    setYouthChoirHarvest(harvestYouthSongs);
    setYouthChoirEaster(easterYouthSongs);
    setYouthChoirCommunion(communionYouthSongs);
    setYouthChoirNewYear(newYearYouthSongs);
    setYouthChoirSisters(womenYouthSongs);
    setYouthChoirBrothers(menYouthSongs);
    setYouthChoirBaptism(baptismYouthSongs);
  }, [youthChoirSongs]);

  const turnOnRegularSongs = () => {
    setSpecificCategory(youthChoirRegular);
    setCategoryName({
      theCategoryName: 'Regular',
    });
  };
  const turnOnChristmasSongs = () => {
    setSpecificCategory(youthChoirChristmas);
    setCategoryName({
      theCategoryName: 'Christmas',
    });
  };
  const turnOnHarvestSongs = () => {
    setSpecificCategory(youthChoirHarvest);
    setCategoryName({
      theCategoryName: 'Harvest',
    });
  };
  const turnOnEasterSongs = () => {
    setSpecificCategory(youthChoirEaster);
    setCategoryName({
      theCategoryName: 'Easter',
    });
  };
  const turnOnCommunionSongs = () => {
    setSpecificCategory(youthChoirCommunion);
    setCategoryName({
      theCategoryName: 'Communion',
    });
  };
  const turnOnNewYearSongs = () => {
    setSpecificCategory(youthChoirNewYear);
    setCategoryName({
      theCategoryName: 'New Year',
    });
  };
  const turnOnMenSongs = () => {
    setSpecificCategory(youthChoirBrothers);
    setCategoryName({
      theCategoryName: 'Mens Choir',
    });
  };
  const turnOnWomenSongs = () => {
    setSpecificCategory(youthChoirSisters);
    setCategoryName({
      theCategoryName: 'Womens Choir',
    });
  };
  const turnOnBaptismSongs = () => {
    setSpecificCategory(youthChoirBaptism);
    setCategoryName({
      theCategoryName: 'Baptism',
    });
  };
  const turnOnAllSongs = () => {
    setSpecificCategory(youthChoirSongs);
    setCategoryName({
      theCategoryName: 'All Songs',
    });
  };

  return (
    <Box
      sx={{
        paddingTop: '20px',
        maxWidth: '800px',
        margin: '0 auto',
      }}
    >
      <Header size='huge' textAlign='center'>
        Youth Choir
      </Header>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          paddingBottom: '15px',
        }}
      >
        <Dropdown
          button
          className='icon'
          floating
          labeled
          icon='angle double down'
          text={categoryName.theCategoryName}
          closeOnChange
        >
          <Dropdown.Menu>
            <Dropdown.Item onClick={turnOnAllSongs}>All Songs</Dropdown.Item>
            <Dropdown.Item onClick={turnOnBaptismSongs}>Baptism</Dropdown.Item>
            <Dropdown.Item onClick={turnOnChristmasSongs}>
              Christmas
            </Dropdown.Item>
            <Dropdown.Item onClick={turnOnCommunionSongs}>
              Communion
            </Dropdown.Item>
            <Dropdown.Item onClick={turnOnEasterSongs}>Easter</Dropdown.Item>
            <Dropdown.Item onClick={turnOnHarvestSongs}>Harvest</Dropdown.Item>
            <Dropdown.Item onClick={turnOnMenSongs}>Mens Choir</Dropdown.Item>
            <Dropdown.Item onClick={turnOnNewYearSongs}>New Year</Dropdown.Item>
            <Dropdown.Item onClick={turnOnRegularSongs}>Regular</Dropdown.Item>
            <Dropdown.Item onClick={turnOnWomenSongs}>
              Womens Choir
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        {/* <Search/> */}
      </Box>
      <Box sx={{mt: '7px', mb: '10px'}}>
        <SearchSong windowWidth={windowWidth} query={query} setQuery={setQuery} />
      </Box>

      {specificCategory
        .filter(({ name }) => name.toLowerCase().includes(query.toLowerCase()))
        .map((song) => (
          <Box
            key={song.fireId}
            sx={{
              justifyContent: 'space-between',
              alignItems: 'center',
              display: 'flex',
              padding: '5px 20px',
              fontSize: '16.5px',
              '@media (max-width: 768px)': {
                padding: '5px 30px',
              },
              '@media (max-width: 550px)': {
                padding: '5px ',
                fontSize: '17.5px',
              },
              transition: 'background ease-out 0.3s',
              borderRadius: '.28571429rem',
              ':hover': { background: '#e5e4e2', color: '#5C095A' },
            }}
          >
            {/* Section below is to edit the song title padding and width */}
            <Box
              sx={{
                padding: '4px',
                overflow: 'hidden',
                '@media (max-width: 630px)': {
                  maxWidth: '45vw',
                },
                '@media (max-width: 500px)': {
                  maxWidth: '50vw',
                },
                '@media (max-width: 360px)': {
                  maxWidth: '43vw',
                  fontSize: '15.5px',
                },
                ':hover': { color: '#152622' },
              }}
            >
              <SongTitle eachSong={song} />
            </Box>
            {/* Section below is to edit the icon padding */}
            <Flex>
              <Flex
                sx={{
                  justifyContent: 'center',
                  paddingRight: '3px',
                }}
              >
                {song.pianoId ? (
                  <Label
                    size='mini'
                    href={`https://drive.google.com/file/d/${song.pianoId}/view?usp=sharing`}
                    target='_blank'
                    style={{
                      height: '29px',
                      dispay: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Image style={{ borderRadius: '2px' }} src={PianoIcon} />
                  </Label>
                ) : null}
                {song.scoreId ? (
                  <Label
                    size='small'
                    href={`https://drive.google.com/file/d/${song.scoreId}/view?usp=sharing`}
                    target='_blank'
                    style={{
                      height: '29px',
                      width: '31px',
                    }}
                  >
                    <Icon name='book' size='large' />
                  </Label>
                ) : null}
                {song.folderId ? (
                  <Label
                    size='small'
                    href={`https://drive.google.com/drive/folders/${song.folderId}?usp=sharing`}
                    target='_blank'
                    style={{
                      height: '29px',
                      width: '34px',
                    }}
                  >
                    <Icon name='folder open' size='large' />
                  </Label>
                ) : null}
              </Flex>
              <ModalWindow eachSong={song} />
            </Flex>
          </Box>
        ))}
    </Box>
  );
};