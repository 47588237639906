import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Segment } from 'semantic-ui-react';

import { SongsContextProvider } from './lib/songs-context';
import { Title } from './components/title';
import { MenuBar, DropdownMenuBar } from './components/navigation';
import { Footer } from './components/footer';
import { YouthChoir } from './components/youth-choir';
import { MainChoir } from './components/main-choir';
import { Strings } from './components/strings';
// import { Ensemble } from './components/ensemble';
// import { Band } from './components/band';
import { Body, QuickLinks, ImageSlider } from './components/body';
import { AdminPage } from './components/admin';

import 'semantic-ui-css/semantic.min.css';
import './styles.css';

const App = () => {
  //Adding global state variables here

  // const addSongs = async () => {
  //   //Adding songs from the array
  //   songs.forEach((song) => {
  //     const input = {
  //       ...song,
  //       category: "ensemble", // TODO: change category here
  //     };

  //     //console.log(input);

  //     //db.collection('songs').add(input);
  //   });
  // };

  // useEffect(() => {
  //   (async () => {
  //     //console.log("FIRING EFFECT");
  //     await fetchSongs();
  //     //await fetchPages();
  //     //await addSongs(); //USE ONLY FOR ADDING SONGS
  //   })();
  // }, []);

  const [widthV, setWidth] = useState(window.innerWidth);

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  });

  return (
    <Router>
      <div
        style={{
          display: 'flex',
          minHeight: '100vh',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <SongsContextProvider>
          <Segment basic>
            <Title />
            {widthV < 500 ? <DropdownMenuBar /> : <MenuBar />}
            <Switch>
              <Route path='/main-choir'>
                <MainChoir windowWidth={widthV}/>
              </Route>

              <Route path='/youth-choir'>
                <YouthChoir windowWidth={widthV}/>
              </Route>

              <Route path='/strings'>
                <Strings windowWidth={widthV}/>
              </Route>

              {/* <Route path='/ensemble'>
                <Ensemble windowWidth={widthV}/>
              </Route> */}

              {/* <Route path='/band'>
                <Band />
              </Route> */}

              <Route path='/admin'>
                <AdminPage windowWidth={widthV}/>
              </Route>

              <Route path='/home'>
                <ImageSlider />
                <Body />
                <QuickLinks />
              </Route>

              <Route path='/'>
                <ImageSlider />
                <Body />
                <QuickLinks />
              </Route>
            </Switch>
          </Segment>
          <Footer />
        </SongsContextProvider>
      </div>
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
