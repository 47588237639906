import { Input } from 'semantic-ui-react';

export const SearchSong = ({ windowWidth, query, setQuery }) => {
  const whatSize = () => {
    if (windowWidth < 550) return 'large';
    else return 'small';
  };

  return (
    <Input
      fluid
      size={whatSize()}
      icon='search'
      value={query}
      placeholder='Type in song'
      onChange={({ target: { value } }) => setQuery(value)}
    />
  );
};
