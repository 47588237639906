import React, { useEffect, useState } from 'react';
import {
  Modal,
  Button,
  Header,
  Form,
  Segment,
  Input,
  Dropdown,
} from 'semantic-ui-react';
import { Box } from 'theme-ui';
import { db } from '../../lib/firebase';

export const UpdateModal = ({ windowWidth, song, collectionName }) => {
  const [open, setOpen] = useState(false);
  const [songName, setSongName] = useState('');
  const [songComposer, setSongComposer] = useState('');
  const [driveId, setDriveId] = useState('');
  const [songLastSang, setSongLastSang] = useState('');
  const [songCategory, setSongCategory] = useState('');
  const [songRecId, setSongRecId] = useState('');
  const [songPianoId, setSongPianoId] = useState('');
  const [songScoreId, setSongScoreId] = useState('');
  const [songFolderId, setSongFolderId] = useState('');

  useEffect(() => {
    setSongName(song.name);
    setSongComposer(song.composer);
    setDriveId(song.id);
    setSongLastSang(song.lastSang);
    setSongCategory(song.category);
    setSongRecId(song.recId);
    setSongPianoId(song.pianoId);
    setSongScoreId(song.scoreId);
    setSongFolderId(song.folderId);
  }, [song]);

  const handleUpdate = async ({ song, collectionName }) => {
    if(!song) return;
    
    const info = {
      name: songName,
      composer: songComposer,
      id: driveId,
      lastSang: songLastSang,
      category: songCategory,
      recId: songRecId,
      pianoId: songPianoId,
      scoreId: songScoreId,
      folderId: songFolderId,
    };

    const goodInfo = {};

    Object.entries(info).forEach(function (currElem) {
      const key = currElem[0];
      const value = currElem[1];

      if (value) {
        goodInfo[key] = value;
      }
    });
    
    try {
      await db.collection(collectionName).doc(song.fireId).set(goodInfo);
    } catch {
      return console.log('ERROR');
    }
  };

  // const deleteSong = async ({ song, collectionName }) => {
  //   console.log(song.fireId);
  //   console.log(collectionName);
  //   try {
  //     await db.collection(collectionName).doc(song.fireId).delete();
  //     // setOpen(false);
  //   } catch {
  //     return console.log('ERROR Deleting a song');
  //   }
  // };

  const whatSize = () => {
    if (windowWidth < 550) return 'large';
    else return 'mini';
  };

  const whatSizeModal = () => {
    if(windowWidth < 550) return 'large';
  }

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={
        <Box>
          <Button color='green' size={whatSize()}>
            Update
          </Button>
        </Box>
      }
    >
      <Modal.Header>{`${song.name}`}</Modal.Header>
      <Modal.Content>
        <Segment basic>
          <Form size={whatSizeModal()} onSubmit={() => handleUpdate({ song, collectionName })}>
            <Form.Field>
              <Header>Song name</Header>
              <Input
                placeholder='Song name'
                value={songName}
                name='songName'
                onChange={(_, { value }) => setSongName(value)}
              />
            </Form.Field>
            <Form.Field>
              <Header>Composer</Header>
              <Input
                placeholder='Composer'
                value={songComposer}
                name='songComposer'
                onChange={(_, { value }) => setSongComposer(value)}
              />
            </Form.Field>
            <Form.Field>
              <Header>Drive ID</Header>
              <Input
                placeholder='Drive ID'
                value={driveId}
                name='driveId'
                onChange={(_, { value }) => setDriveId(value)}
              />
            </Form.Field>
            <Form.Field>
              <Header>Last Sang</Header>
              <Input
                placeholder='Last Sang'
                value={songLastSang}
                name='songLastSang'
                onChange={(_, { value }) => setSongLastSang(value)}
              />
            </Form.Field>
            <Form.Field>
              <Header>Category</Header>
              <Dropdown
                placeholder='Category'
                value={songCategory}
                onChange={(_, { value }) => setSongCategory(value)}
                fluid
                selection
                options={[
                  { key: 'baptism', value: 'baptism', text: 'Baptism' },
                  { key: 'christmas', value: 'christmas', text: 'Christmas' },
                  { key: 'communion', value: 'communion', text: 'Communion' },
                  { key: 'easter', value: 'easter', text: 'Easter' },
                  { key: 'harvest', value: 'harvest', text: 'Harvest' },
                  {
                    key: 'mens-choir',
                    value: 'mens-choir',
                    text: "Men's Choir",
                  },
                  { key: 'new-year', value: 'new-year', text: 'New Year' },
                  { key: 'regular', value: 'regular', text: 'Regular' },
                  {
                    key: 'womens-choir',
                    value: 'womens-choir',
                    text: "Women's Choir",
                  },
                ]}
              />
            </Form.Field>
            <Form.Field>
              <Header>Recording ID</Header>
              <Input
                placeholder='Recording ID'
                value={songRecId}
                name='songRecId'
                onChange={(_, { value }) => setSongRecId(value)}
              />
            </Form.Field>
            <Form.Field>
              <Header>Piano ID</Header>
              <Input
                placeholder='Piano ID'
                value={songPianoId}
                name='songPianoId'
                onChange={(_, { value }) => setSongPianoId(value)}
              />
            </Form.Field>
            <Form.Field>
              <Header>Score ID</Header>
              <Input
                placeholder='Score ID'
                value={songScoreId}
                name='songScoreId'
                onChange={(_, { value }) => setSongScoreId(value)}
              />
            </Form.Field>
            <Form.Field>
              <Header>Folder ID</Header>
              <Input
                placeholder='Folder ID'
                value={songFolderId}
                name='songFolderId'
                onChange={(_, { value }) => setSongFolderId(value)}
              />
            </Form.Field>
            {songName && songCategory ? (
              <Form.Button color='yellow' type='submit'>
                Update
              </Form.Button>
            ) : (
              <Box></Box>
            )}
            {/* <Button color='red' onClick={() => deleteSong({song, collectionName})}>
              Delete
            </Button> */}
          </Form>
        </Segment>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setOpen(false)}>Done</Button>
      </Modal.Actions>
    </Modal>
  );
};
