import { createContext, useContext, useState, useEffect } from 'react';

import { db } from './firebase';

export const SongsContext = createContext({});

export const SongsContextProvider = ({ children }) => {
  const [stringsSongs, setStringsSongs] = useState([]);
  const [ensembleSongs, setEnsembleSongs] = useState([]);
  const [youthChoirSongs, setYouthChoirSongs] = useState([]);
  const [mainChoirSongs, setMainChoirSongs] = useState([]);

  const fetchStringsSongs = async () => {
    const snapshot = await db
      .collection('strings-songs')
      .orderBy('name', 'asc')
      .get();

    const res = snapshot.docs.map((doc) => ({
      fireId: doc.id,
      ...doc.data(),
    }));

    setStringsSongs(res);
  };

  const fetchEnsembleSongs = async () => {
    const snapshot = await db
      .collection('ensemble-songs')
      .orderBy('name', 'asc')
      .get();

    const res = snapshot.docs.map((doc) => ({
      fireId: doc.id,
      ...doc.data(),
    }));

    setEnsembleSongs(res);
  };

  const fetchYouthChoirSongs = async () => {
    const snapshot = await db
      .collection('youth-choir-songs')
      .orderBy('name', 'asc')
      .get();

    const res = snapshot.docs.map((doc) => ({
      fireId: doc.id,
      ...doc.data(),
    }));

    setYouthChoirSongs(res);
  };
  
  const fetchMainChoirSongs = async () => {
    const snapshot = await db
      .collection('main-choir-songs')
      .orderBy('name', 'asc')
      .get();

    const res = snapshot.docs.map((doc) => ({
      fireId: doc.id,
      ...doc.data(),
    }));

    setMainChoirSongs(res);
  };

  useEffect(() => {
    (async () => {
      await fetchStringsSongs();
      await fetchEnsembleSongs();
      await fetchYouthChoirSongs();
      await fetchMainChoirSongs();
    })();
  }, []);

  const context = {
    stringsSongs,
    ensembleSongs,
    youthChoirSongs,
    mainChoirSongs,
  };

  return (
    <SongsContext.Provider value={context}>{children}</SongsContext.Provider>
  );
};

export const useSongsContext = () => {
  const context = useContext(SongsContext);
  return context;
};
