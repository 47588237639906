import React from 'react';
import {Segment} from 'semantic-ui-react'

var d = new Date();
var fullYear = d.getFullYear();

export const Footer = () =>(
    <Segment textAlign="center" inverted color="grey" attached="bottom">
        GSBC Music Department | V.S. {fullYear}
    </Segment>
);