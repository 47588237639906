import React from 'react';
import { Modal, Button, Icon, Label } from 'semantic-ui-react';
import { Box } from 'theme-ui';
import ReactAudioPlayer from 'react-audio-player';

export const ModalWindow = (props) => {
  return (
    <Modal
      trigger={
        <Box>
          <Button color='green' size='mini'>
            <Icon fitted name='info'/>
          </Button>
        </Box>
      }
      header={`${props.eachSong.name}`}
      content={
        <Box>
          {props.eachSong.recId ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                paddingTop: '12px',
              }}
            >
              <ReactAudioPlayer
                src={`https://docs.google.com/uc?export=download&id=${props.eachSong.recId}`}
                controls
              />
            </Box>
          ) : null}
          <Box
            sx={{
              textAlign: 'center',
              paddingTop: '10px',
              paddingBottom: '10px',
              justifyContent: 'space-between',
            }}
          >
            <Label size='large' basic>
              {`Composer: ${
                props.eachSong.composer ? props.eachSong.composer : 'None'
              }`}
            </Label>
            <Label size='large' color='blue' basic>
              {`Last Sang: ${
                props.eachSong.lastSang ? props.eachSong.lastSang : 'None'
              }`}
            </Label>
          </Box>
        </Box>
      }
      actions={[{ key: 'done', content: 'Done', positive: true }]}
    />
  );
};

export const ModalWindowNoDate = (props) => {
  return (
    <Modal
      trigger={
        <Box>
          <Button color='green' size='mini'>
            <Icon fitted name='info'/>
          </Button>
        </Box>
      }
      header={`${props.eachSong.name}`}
      content={
        <Box>
          {props.eachSong.recId ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                paddingTop: '12px',
              }}
            >
              <ReactAudioPlayer
                src={`https://docs.google.com/uc?export=download&id=${props.eachSong.recId}`}
                controls
              />
            </Box>
          ) : null}
          <Box
            sx={{
              textAlign: 'center',
              paddingTop: '10px',
              paddingBottom: '10px',
              justifyContent: 'space-between',
            }}
          >
            <Label size='large' basic>
              {`Composer: ${
                props.eachSong.composer ? props.eachSong.composer : 'None'
              }`}
            </Label>
          </Box>
        </Box>
      }
      actions={[{ key: 'done', content: 'Done', positive: true }]}
    />
  );
};

export const SongTitle = (props) => {
  return (
    <Box
      sx={{
        '@media (max-width: 380px)': {
          maxWidth: '250px',
        },
      }}
    >
      <a
        href={`https://drive.google.com/file/d/${props.eachSong.id}/view?usp=sharing`}
        target='_blank'
        rel='noreferrer'
      >
        {props.eachSong.name}
      </a>
    </Box>
  );
};

export const EnsembleTitle = (props) => {
  return (
    <Box
      sx={{
        '@media (max-width: 380px)': {
          maxWidth: '250px',
        },
      }}
    >
      <a
        href={`https://drive.google.com/drive/folders/${props.eachSong.id}?usp=sharing`}
        target='_blank'
        rel='noreferrer'
      >
        {props.eachSong.name}
      </a>
    </Box>
  );
};
