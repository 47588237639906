import React, { useState } from 'react';
import { useSongsContext } from '../../lib/songs-context';
import { Header, Accordion, Icon } from 'semantic-ui-react';
import { Box } from 'theme-ui';
import { SearchSong } from '../search';
import { DisplaySearchedSongs } from '../search/display-searched';

export const SongUpdate = ({ windowWidth }) => {
  const { youthChoirSongs, mainChoirSongs, stringsSongs } = useSongsContext();
  const [mainChoirQuery, setMainChoirQuery] = useState('');
  const [youthChoirQuery, setYouthChoirQuery] = useState('');
  const [stringsQuery, setStringsQuery] = useState('');
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  const whatSize = () => {
    if (windowWidth < 550) return '20px';
  };

  return (
    <Box sx={{ width: '700px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: '20px' }}>
        <Header as='h1'>Update a Song</Header>
      </Box>
      <Accordion fluid styled>
        <Accordion.Title
          active={activeIndex === 0}
          index={0}
          onClick={handleClick}
          style={{ fontSize: whatSize() }}
        >
          <Icon name='dropdown' />
          Main Choir
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 0}>
          <SearchSong
            windowWidth={windowWidth}
            query={mainChoirQuery}
            setQuery={setMainChoirQuery}
          />
          <DisplaySearchedSongs
            windowWidth={windowWidth}
            key={'mainChoirSongs'}
            songCollection={mainChoirSongs}
            query={mainChoirQuery}
            collectionName={'main-choir-songs'}
          />
        </Accordion.Content>
        <Accordion.Title
          active={activeIndex === 1}
          index={1}
          onClick={handleClick}
          style={{ fontSize: whatSize() }}
        >
          <Icon name='dropdown' />
          Youth Choir
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 1}>
          <SearchSong
            windowWidth={windowWidth}
            query={youthChoirQuery}
            setQuery={setYouthChoirQuery}
          />
          <DisplaySearchedSongs
            windowWidth={windowWidth}
            key={'youthChoirSongs'}
            songCollection={youthChoirSongs}
            query={youthChoirQuery}
            collectionName={'youth-choir-songs'}
          />
        </Accordion.Content>
        <Accordion.Title
          active={activeIndex === 2}
          index={2}
          onClick={handleClick}
          style={{ fontSize: whatSize() }}
        >
          <Icon name='dropdown' />
          Strings
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 2}>
          <SearchSong
            windowWidth={windowWidth}
            query={stringsQuery}
            setQuery={setStringsQuery}
          />
          <DisplaySearchedSongs
            windowWidth={windowWidth}
            key={'stringsSongs'}
            songCollection={stringsSongs}
            query={stringsQuery}
            collectionName={'strings-songs'}
          />
        </Accordion.Content>
      </Accordion>
    </Box>
  );
};
