import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
export const config = {
    apiKey: "AIzaSyB--qXB59sds3-pAne3wFDjURXu-TH0RYo",
    authDomain: "gsbc-music-dept.firebaseapp.com",
    projectId: "gsbc-music-dept",
    storageBucket: "gsbc-music-dept.appspot.com",
    messagingSenderId: "622531950223",
    appId: "1:622531950223:web:7361513f27b56bcd1ae2fa"
  };
export const fire = firebase.initializeApp(config);
export const db = fire.firestore();
export const provider = new firebase.auth.GoogleAuthProvider();
// var provider = new firebase.auth.GoogleAuthProvider();
// export const provider = firebase.auth.GoogleAuthProvider();
