import React, { useEffect, useState } from 'react';
import { Segment, Button, Header, Image } from 'semantic-ui-react';
// import SimpleImageSlider from 'react-simple-image-slider';
import { Box } from 'theme-ui';

import FirstSlideImage from '../../main-page-pics/BigGroupPic.JPG';
// import SecondSlideImage from '../../main-page-pics/NovSundaie-03.jpg';
// import ThirdSlideImage from '../../main-page-pics/NovSundaie-23.jpg';

export const ImageSlider = () => {
  const [widthV, setWidth] = useState(window.innerWidth);

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  });

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        padding: '20px',
        paddingTop: '25px',
      }}
    >
      {/* {widthV < 600 ? (
        <Box>
          <SimpleImageSlider
            width={290}
            height={200}
            images={picImages}
            showNavs
          />
        </Box>
      ) : (
        <SimpleImageSlider
          width={496}
          height={320}
          images={picImages}
          showNavs
        />
      )} */}
      {widthV < 600 ? (
        <Box sx={{ paddingTop: '10px' }}>
          <Image
            src={FirstSlideImage}
            style={{
              width: '290px',
              borderRadius: '10px',
              boxShadow: '0 0 10px #1d3c2c',
            }}
          />
        </Box>
      ) : (
        <Box sx={{ paddingTop: '15px' }}>
          <Image
            src={FirstSlideImage}
            style={{
              width: '496px',
              borderRadius: '10px',
              boxShadow: '0 0 10px #1d3c2c',
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export const Body = () => (
  <Box
    style={{
      paddingLeft: '20px',
      paddingRight: '20px',
      paddingTop: '15px',
      fontSize: '18px',
      textAlign: 'center',
    }}
  >
    <p>
      Welcome to the GSBC musical department! This website is designed to be a
      source for the sheet music that is used in the various musical ministries
      of Grace Slavic Baptist Church in Spokane Washington.
    </p>

    <p>
      Добро пожаловать в музыкальный отдел GSBC! Этот сайт разработан как
      источник нот, которые используются в различных музыкальных отделов церкви
      "Благодать" в городе Spokane, Washington.
    </p>
  </Box>
);

export const QuickLinks = () => (
  <Segment basic textAlign='center' size='large'>
    <Header textAlign='center' size='large'>
      Quick Links:
    </Header>
    <Button.Group basic vertical>
      <Button href='/admin'>Admin Page</Button>
      <Button
        href='https://noty-bratstvo.org/'
        target='_blank'
        rel='noreferrer'
      >
        Ноты Братство
      </Button>

      <Button href='https://mscmusic.org/' target='_blank' rel='noreferrer'>
        MSC Music
      </Button>

      <Button href='https://iosifnichita.com/' target='_blank' rel='noreferrer'>
        Iosif Nichita
      </Button>

      <Button
        href='https://www.sacredmusicvk.com/'
        target='_blank'
        rel='noreferrer'
      >
        Виталий Кривенков
      </Button>
    </Button.Group>
  </Segment>
);