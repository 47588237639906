import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Menu } from 'semantic-ui-react';
import { Box } from 'theme-ui';

import { NavWrapper } from './styled';

const pagesArray = [
  {
    name: 'Home',
    url: '/home',
  },
  {
    name: 'Main Choir',
    url: '/main-choir',
  },
  {
    name: 'Youth Choir',
    url: '/youth-choir',
  },
  {
    name: 'Strings',
    url: '/strings',
  },
  // {
  //   name: 'Ensemble',
  //   url: '/ensemble',
  // },
  // {
  //   name: 'Band',
  //   url: '/band',
  // },
];

// export const Navigation = () => (
//   <NavWrapper>
//     {pages.map((info) => (
//       <StyledNavLink key={info.url} to={info.url} activeClassName='active'>
//         {info.name}
//       </StyledNavLink>
//     ))}
//   </NavWrapper>
// );

//console.log({pages})





export const MenuBar = () => (
  <NavWrapper>
    <Menu compact pointing>
      {pagesArray.map((info) => (
        <Menu.Item
          as={Link}
          key={info.url}
          to={info.url}
        >
          {info.name}
        </Menu.Item>
      ))}
    </Menu>
  </NavWrapper>
);

export const DropdownMenuBar = () => (
  <Box sx={{
    justifyContent: 'center',
    paddingLeft: '20px',
    paddingRight: '20px',
  }}>
    <Menu attached='top' widths='1' fluid>
      <Dropdown item text='Pages'>
        <Dropdown.Menu>
          {pagesArray.map((info) => (
            <Dropdown.Item as={Link} key={info.url} to={info.url}>
              {info.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </Menu>
  </Box>
);
